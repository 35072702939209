import { useContext, useState } from "react";
import { client } from "../../../../api";
import { ModalContext } from "../../../../contexts/modal.context";

export const SimpleInputModalTableItem = ({ disabled, abbrev=false, labelText, endpoint, value, fieldName, identifyingData, updateOrders=()=>{} }) => {
  const { openModal } = useContext(ModalContext);
  const [edited, setEdited] = useState(false);

  const handleEditClick = () => {
    openModal({
      prompt: labelText,
      onSave: (value) => {
        const editOrder = async () => {
          identifyingData[fieldName] = value;
  
          await client({
            method: 'POST',
            url: endpoint,
            headers: {
              "Content-Type": "application/json",
            },
            data: identifyingData,
          });
          await updateOrders(identifyingData);
          setEdited(true);
          setTimeout(() => {
            setEdited(false);
          }, 2000);
        };
        
        editOrder();
      }
    });
  };

  return (
    <div className={`table-cell text-right text-xs rounded-md pl-2 font-medium ${disabled ? 'text-gray-500 cursor-default' : !edited ? 'text-blue-500 underline cursor-pointer' : 'text-white bg-green-500'}  `} onClick={disabled ? () => {} : handleEditClick}>
      {!edited ? (value && abbrev ? value.slice(-20) : value ? value : 'Undefined') : 'edited!'}
    </div>
  );
};

// export const SimpleInputModalTableItem = ({ labelText, endpoint, value, fieldName, identifyingData, updateOrders=()=>{} }) => {
//   const [edited, setEdited] = useState(false);
//   const [inputValue, setInputValue] = useState(value?.toString());

//   useEffect(() => {
//     setInputValue(value?.toString());
//   }, [value]);

//   const handleEditClick = () => {
//     const modal = document.querySelector("#orderedEditModal");
//     const header = modal.getElementsByTagName("h2")[0];
//     const input = modal.getElementsByTagName("input")[0];
//     const saveButton = modal.getElementsByTagName("button")[0];
//     const closeButton = modal.getElementsByTagName("button")[1];

//     header.textContent = labelText;
//     input.value = '';
//     input.selectionStart = input.selectionEnd = input.value.length;
//     modal.showModal();

//     const onSaveChange = () => {
//       const editOrder = async () => {
//         identifyingData[fieldName] = input.value;

//         await client({
//           method: 'POST',
//           url: endpoint,
//           headers: {
//             "Content-Type": "application/json",
//           },
//           data: identifyingData,
//         });
//         setInputValue(input.value);
//         updateOrders(identifyingData);
//         setEdited(true);
//         setTimeout(() => {
//           setEdited(false);
//         }, 2000);
//       };

//       if (input.value !== inputValue) {
//         editOrder();
//       }
      
//       saveButton.removeEventListener("click", onSaveChange);
//       closeButton.removeEventListener("click", handleClose);
//       modal.removeEventListener("cancel", handleClose);
//       modal.close();
//     }

//     const handleClose = (event) => {
//       event.preventDefault();

//       saveButton.removeEventListener("click", onSaveChange);
//       closeButton.removeEventListener("click", handleClose);
//       modal.removeEventListener("cancel", handleClose);
//       modal.close();
//     };

//     saveButton.addEventListener("click", onSaveChange);
//     closeButton.addEventListener("click", handleClose);
//     modal.addEventListener("cancel", handleClose);
//   };

//   return (
//     <div className={`table-cell text-right text-xs rounded-md pl-2 font-medium ${!edited ? 'text-blue-500 underline' : 'text-white bg-green-500'} cursor-pointer `} onClick={handleEditClick}>
//       {!edited ? inputValue : 'edited!'}
//     </div>
//   );
// };